export default {
  content: {
    flexDirection: `row`,
  },
  media: {
    flexBasis: `1/3`,
    m: 2,
    mr: 0,
  },
  image: {
    minHeight: `8.3rem`,
    maxHeight: `none`,
    img: {
      borderRadius: `default`,
    },
  },
  iconBox: {
    borderRadius: `default`,
    minHeight: `8.3rem`,
  },
  iconText: {
    display: [`none`, `block`],
  },
  icon: {
    size: `icon.md`,
  },
  body: {
    p: 3,
  },
  category: {
    display: `inline-block`,
  },
  title: {
    variant: `text.h3`,
  },
  excerpt: {
    display: `none`,
  },
  footer: {
    variant: `text.small`,
  },
  authorPhoto: {
    display: `none`,
  },
  author: {
    display: `inline-block`,
    flexBasis: `auto`,
  },
  date: {
    display: `inline-block`,
  },
  timeToRead: {
    display: `none`,
  },
};

import group from './group';
import primary from './primary';
import mute from './mute';
import disabled from './disabled';
import dark from './dark';
import white from './white';
import simple from './simple';
import icon from './icon';

export default {
  group,
  primary,
  mute,
  disabled,
  dark,
  white,
  simple,
  icon,
};

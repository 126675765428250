import primary from './primary';
import horizontal from './horizontal';
import horizontalAside from './horizontal-aside';
import horizontalLg from './horizontal-lg';
import horizontalHero from './horizontal-hero';
import horizontalMd from './horizontal-md';
import horizontalCover from './horizontal-cover';
import horizontalCoverWide from './horizontal-cover-wide';
import horizontalCoverHero from './horizontal-cover-hero';
import vertical from './vertical';
import verticalCover from './vertical-cover';
import paper from './paper';
import paperLg from './paper-lg';
import search from './search';
import interactive from './interactive';

export default {
  primary,
  horizontal,
  'horizontal-aside': horizontalAside,
  'horizontal-lg': horizontalLg,
  'horizontal-hero': horizontalHero,
  'horizontal-md': horizontalMd,
  'horizontal-cover': horizontalCover,
  'horizontal-cover-wide': horizontalCoverWide,
  'horizontal-cover-hero': horizontalCoverHero,
  vertical,
  'vertical-cover': verticalCover,
  paper,
  'paper-lg': paperLg,
  search,
  interactive,
};
